@media only screen and (max-width: 670px) {
  .addressContainer {
    padding-bottom: 15px;
  }

  .contentContainer > div {
    flex-basis: 100%;
  }
}

@media only screen and (min-width: 670px) {
  .addressContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.sectionContainer {
  padding: 15px;
}

.contentContainer {
  display: flex;
  flex-wrap: wrap;
}

.hoursContainer {
  flex: 1;
}

.locationContainer {
  flex: 1;
  margin-bottom: 15px;
}

.locationContainer iframe {
  width: 100%;
  height: 100%;
}

.addressContainer {
  flex: 1;
}

.addressContainer h2 {
  margin-top: 0;
}

.hoursTable {
  margin-bottom: 16px;
}
