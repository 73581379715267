.deliveryRadiusMap {
  width: 100%;
  height: auto;
}

.deliveryOptionContainer {
  height: 100%;
}

.deliveryOption {
  width: 100%;
}

.deliveryOption.deliveryOption {
  margin-bottom: 15px;
}

@media only screen and (max-height: 891px) and (orientation: landscape) {
  .deliveryRadiusMap {
    display: none;
  }
}

.leaflet-container {
  height: 350px;
}
