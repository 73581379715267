.top-alert {
  position: fixed;
  width: 100%;
  background-color: #da2d2d;
  display: flex;
  padding: 10px;
  font-weight: bold;
  color: white;
  z-index: 2;
  left: 0;
  bottom: 0;
}

@media only screen and (max-width: 717px) {
  .orderOnline {
    width: 100%;
  }
  .top-alert {
    height: 80px;
  }
  .socialMedia {
    display: none;
  }
  .alertItem {
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 385px) {
  .infoAddressContainer {
    width: 308px;
  }
}

@media only screen and (min-width: 717px) {
  .infoContainer:not(.lastInfoContainer)::after {
    content: " ";
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid white;
    height: 18px;
  }
  .top-alert {
    height: 20px;
  }
}

.top-alert a {
  color: white;
  text-decoration: none;
}

.top-alert a:visited {
  color: white;
}

.orderOnline a {
  font-size: 18px;
  font-weight: bolder;
  color: white;
}

.infoContainer {
  display: flex;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}

.infoContainer span {
  height: 24px;
}

.alertItem {
  padding-bottom: 5px;
}

.alertContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.socialMedia {
  width: 60px;
}

.socialMedia ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
