.aboutImage {
  display: block;
  margin: auto;
  flex-basis: 300px;
  max-width: 300px;
}

.aboutContainer {
  display: flex;
  flex-wrap: wrap;
}

.aboutContent {
  padding: 10px;
  flex: 1;
}
