.announcementContainer {
  width: 100%;
  background-color: #da2d2d;
  color: white;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5px;
}

.announcementContainer p {
  color: white;
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: bolder;
}
