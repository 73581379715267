.fulfillmentOptionContainer {
  display: flex;
  flex-wrap: wrap;
}

.fulfillmentOption {
  width: 100%;
}

.fulfillmentOption.fulfillmentOption {
  margin-bottom: 15px;
}
