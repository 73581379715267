.dineInContainer {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: auto;
  max-width: 400px;
}

.diningTableContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
