@media only screen and (min-width: 670px) {
  .Banner.Banner {
    width: 700px;
    height: 400px;
    margin: auto;
  }
}

.media.media {
  width: 100%;
  height: auto;
}

.BannerGrid {
  height: 100%;
}

.Banner {
  height: 200px;
}

.MediaCaption {
  position: absolute;
  bottom: 0;

  background-color: black;
  color: white;
  opacity: 0.8;
  text-align: center;
  padding: 5px;
}
