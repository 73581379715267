.App {
  margin-bottom: 100px;
}

h1,
h2 {
  margin-top: 0;
}

html,
body {
  max-width: 100%;
  height: 100%;
}
