@media only screen and (min-width: 700px) {
  .carousel {
    width: 700px;
    margin: auto;
  }
}

.carouselContainer {
  background-color: #4a4848;
}
