@media only screen and (max-width: 500px) {
  .modalContainer.modalContainer {
    width: auto;
    height: 100%;
  }
}

.modalContainer {
  width: 500px;
  margin: auto;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  padding: 15px;
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
}
