.groupContainer h2 {
  font-size: 32px;
  margin-top: 25px;
}

.menuName {
  text-align: center;
  font-size: 48px;
}

.itemContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.menuItemContainer {
  display: flex;
  flex-flow: column;
  padding: 10px;
}

.menuItem {
  border: 1px solid gray;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 24%), 0 0 2px 0 rgb(0 0 0 / 12%);
  border-radius: 5px;
  flex-basis: 528px;
  margin: 10px 10px 0 0;
  display: flex;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
}

.itemName {
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 5px;
}

.itemImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemImage img {
  width: 150px;
  object-fit: cover;
  height: 100%;
}

.itemDescription {
  color: #6b6666;
  margin-bottom: 10px;
  font-size: 14px;
  white-space: pre-wrap;
  -webkit-line-clamp: 5;
  max-height: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

@media only screen and (max-width: 1115px) {
  .itemDescription {
    -webkit-line-clamp: 4;
    max-height: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .onlineMenuContainer.onlineMenuContainer {
    max-width: 535px;
  }
}

.onlineMenuContainer {
  max-width: 1080px;
  margin: auto;
}

.itemPrice {
  margin-top: auto;
}

.itemPrice p {
  margin-bottom: 0;
}

.itemPriceContainer {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.itemSmallPrice {
  margin-right: 10px;
}

.noodlePrice {
  width: 100%;
  display: flex;
}

.noodlePrice strong {
  width: 100%;
}

.familyDinner {
  width: 100%;
}

.menuHeader {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: white;
  height: 50px;
  overflow: hidden;
}

.menuGroupName {
  display: inline-block;
  margin: 10px;
  width: max-content;
  cursor: pointer;
}

.menuGroupName.selected {
  border-bottom: 3px solid red;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
