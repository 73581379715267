.itemDetailsImage {
  width: 100%;
}

.itemDetailsDescription {
  white-space: pre-wrap;
}

.itemDetailsOrder {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
