@media only screen and (max-width: 670px) {
  .logo {
    grid-column-start: 1;
  }
}

@media only screen and (min-width: 670px) {
  .logo {
    grid-column-start: 2;
  }
}

.menuContainer {
  margin: auto;
}

.name {
  text-align: center;
  margin: 0;
}

h2.name {
  font-size: 15px;
}

.logo-img {
  width: 75px;
  height: 64px;
  background-image: url("https://newpeking.net/images/logo.png");
  background-size: 75px 64px;
}

.logoContainer {
  display: grid;
  align-items: center;
  background-color: black;
  color: white;
  padding: 10px;
  grid-template-columns: 1fr repeat(3, auto) 1fr;
  justify-content: center;
}

.orderNow {
  margin-left: auto;
}

.logo {
  display: flex;
  width: 216px;
  cursor: pointer;
}

.orderNow {
  margin-left: auto;
  grid-column-start: 5;
}

.subscribeButton {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
